
//gbh code below
.collapsed-widget-wrapper {
  position: fixed;
  bottom: 20px;
  width: 100%;
  z-index: 99;
  pointer-events: none;
  @include breakpoint(tab) {
    z-index: 998;
  }
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    display: none;
  }
}

.kf-react-plp-container {
  .collapsed-widget {
    border-radius: 5px;
    background-color: $color-medium-blue;
  }
  .collapsed-widget__compare-details {
    .collapsed-widget__number,
    .collapsed-widget__products-compare-text {
      color: $color-white;
      font-family: $lato-regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    .collapsed-widget__number {
      height: 24px;
      width: 10px;
    }
  }
}

.compare-products {
  font-family: $helvetica-light;
  img {
    filter: invert(100%);
  }
  &__title-text {
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 22px;
    display: block;
    @include breakpoint(desktop-small){
      max-width: 110px;
    }
    @include breakpoint(tab) {
      font-size: 18;
      letter-spacing: 0;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }

  &__button {
    color: $color-medium-blue-cap;
    font-family: $lato-bold;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2.5px;
    line-height: 24px;
    text-align: center;
    border: 1px solid $color-medium-blue-cap;
    border-radius: 1px;
    background-color: $cool-light-grey;
    margin: 22px 0 20px;
    text-transform: uppercase;
    padding: 12px 27px 14px 27px;
    width: 85%;
  }

  &__button-link {
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    @include breakpoint(desktop-small) {
      white-space: nowrap;
    }
    @include breakpoint(tab) {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 20px;
    }
  }

  &__highlights {
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 69px;
    margin-bottom: 29px;
    border-bottom: 1px solid $cool-dark-grey-cap;
    @include breakpoint(desktop-mid) {
      margin-left: 10px;
    }
    .highlights-lable {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      @include breakpoint(desktop-mid) {
        margin: 0 10px;
      }
    }
  }

  &__sticky-header {
    background-color: $cool-light-grey;
  }

  &__sticky-header-differences {
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__print-share-whislist {
    .print-share-link {
      .label-name {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  &__half-screen-content {
    @include breakpoint(desktop-small) {
      justify-content: space-between;
    }
    .compare-products {
      &__left-side {
        max-width: 13.116%;
        @include breakpoint(desktop-mid){
          max-width: 25%;
        }
        @include breakpoint(tab){
          max-width: 21%;
          width: 100%;
        }
      }
    }
  }

  .compare-card-item {
    &__skuid {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 20px 0 7px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    &__name {
      color: $text-dark-grey;
      font-family: $font-lato;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      margin-top: 0px;
      margin-bottom: 0;
      @include breakpoint(tab) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }
    }
    &__description {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 20px;
      margin-top: 7px;
      @include breakpoint(tab) {
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
      }
    }
    &__list-price {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__cart-button {
      color: $color-medium-blue;
      font-family: $font-lato;
      background-color: $cool-light-grey;
      border: 1px solid $color-medium-blue;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 10px;
      text-align: center;
      padding: 15px 26px;
      display: block;
      width: 100%;
    }
  }
  .place-holder {
    &__box {
      border: 1px solid $cool-dark-grey;
      display: flex;
    }
    &__num {
      background-color: $cool-light-grey;
      position: relative;
      margin: 50% auto;
      bottom: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .product-box {
    display: inline-block;
    z-index: 6;
    &.compare-col-hover {
      background-color: $cool-medium-grey;
    }
    &.col-1-hover {
      background-color: $cool-medium-grey;
      .compare-card-item__cart-button {
        background-color: $cool-medium-grey;
      }
    }
    &.col-2-hover {
      background-color: $cool-medium-grey;
      .compare-card-item__cart-button {
        background-color: $cool-medium-grey;
      }
    }
    &.col-3-hover {
      background-color: $cool-medium-grey;
      .compare-card-item__cart-button {
        background-color: $cool-medium-grey;
      }
    }
    &.col-4-hover {
      background-color: $cool-medium-grey;
      .compare-card-item__cart-button {
        background-color: $cool-medium-grey;
      }
    }
  }
  &__full-screen {
    .collapsible-accordion .Collapsible .accordion__header span {
      font-family: $lato-regular;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: normal;
      color: $text-dark-grey;
    }
    .collapsible-accordion .Collapsible__trigger {
      padding: 28px 0 !important;
      position: relative;
      z-index: 99;
      @include breakpoint(desktop-mid){
        padding-left: 2.25rem!important;
      }
      @include breakpoint(tab) {
        padding: 14px 0;
      }
    }
    .collapsible-accordion
      .compare-products__accordion-content
      .compare-products__spec-comparison
      .compare-products__spec-values
      .compare-col-hover {
      background-color: $color-extra-light-gray;
    }
    section {
      @include ann-describtion(16px, $lato-regular, 36px);
      letter-spacing: 0;
      color: $text-dark-grey;
    }
  }
  .place-holder__box--compared {
    width: 100%;
  }
  .place-holder__box,
  .place-holder__box--compared {
    border-radius: 4px;
    height: 324px;
    margin-left: auto;
    position: relative;
    font-size: 16px;
    border: 1px solid $slate;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .place-holder__box {
    width: 100%;
  }
  .place-holder__num {
    padding: 0;
    text-align: center;
    color: $slate;
    font-size: 10pc;
    font-weight: 300;
    line-height: 0.8;
  }
  .compare-card-item__name {
    height: 22px;
  }
  .compare-card-item__name {
    .highlight-text {
      font-family: $lato-regular;
    }
  }
  .compare-card-item__list-price {
    margin-bottom: 5px;
    span{
      &.compare-card-item__list-price__UOM{
        color: $text-medium-dark-grey;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.67px;
        font-family: $lato-regular;
      }
    }
  }
  @media screen and (max-height: 580px), screen and (max-width: 1280px) {
    padding: 40px 15px 0;
  }
  .compare-card-item__image-container,
  .compare-card-item__image-container--compared {
    height: 324px;
  }
  background-color: $cool-light-grey;
  @media only screen and (min-width: 1263px) {
    padding: 55px 32px 50px !important;
  }
  .product-box.compare-col-hover {
    background-color: $light-white;
  }

  .compare-card-item {
    position: relative;
  }

  .compare-card-item__close-icon {
    position: absolute;
    right: 18px;
    top: 13px;
    z-index: 1;
    border: 0;
    background: transparent;
  }

  .compare-card-item__description {
    height: 44px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include breakpoint(desktop-small){
      height: auto;
      margin-bottom: 1.5625rem;
    }
  }
  .compare-card-item .compare-card-img {
    display: block;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }
  .compare-card-item__slashed-price {
    text-decoration: line-through;
    font-family: $helvetica-regular;
    color: $cool-dark-grey;
    font-size: 1pc;
    line-height: 22px;
    letter-spacing: -0.8px;
  }

  .compare-card-item {
    position: relative;
  }

  .compare-card-item__close-icon {
    position: absolute;
    right: 18px;
    top: 13px;
    z-index: 1;
    border: 0;
    background: transparent;
  }

  .compare-card-item__description {
    height: 44px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .compare-card-item .compare-card-img {
    display: block;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }
  .compare-card-item__slashed-price {  
    text-decoration:none;    
    font-family: $helvetica-regular;
    color: $cool-dark-grey;
    font-size: 1pc;
    line-height: 22px;
    letter-spacing: -0.8px;
    span{
      &.compare-card-item__slashed-price__UOM{
        color: $text-medium-dark-grey;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.67px;
        font-family: $lato-regular;
      }
    }
  }
  .compare-card-item__slashed-price{
    span:first-child{
      text-decoration: line-through;
    }
  }
  .compare-card-item__cart-button.disabled {
    opacity: 1;
    background-color: transparent;
    border: 1px solid $cool-dark-grey;
    color: $cool-dark-grey;
  }
  .compare-card-item__cart-button {
    margin-top: 20px;
  }
  .Button_buttonWrapper__TBDg1 {
    .button.compare-card-item__cart-button {
      color: $color-medium-blue;
      font-family: $lato-regular;
      background-color: $cool-light-grey;
      border: 1px solid $color-medium-blue;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 2.5px;
      line-height: 10px;
      text-align: center;
    }
  }
  .compare-card-item__price-section {
    min-height: 50px;
  }
}
.compare-products__button-link,
.compare-products__print-share-whislist .print-share-link,
.compare-products__title-text {
  @include breakpoint(desktop-mid) {
    margin-left: 0px;
  }
}
.compare-products__overlay--compared .compare-products {
  padding: 40px 0 50px !important;
}
img.compare-products__sticky-collapse {
  filter: invert(100%);
}
.compare-products__highlights {
  margin-top: 69px;
  margin-bottom: 29px;
  border-bottom: 1px solid $cool-dark-grey;
  display: flex;
  padding-bottom: 30px;
  justify-content: space-between;
  .highlights-lable {
    position: relative;
    width: 57%;
  }
  .hightlights-switch {
    height: 26px;
    margin: auto;
  }
}
.compare-products {
  &__sticky-wrapper {
    position: relative;
    z-index: 999;
  }
  &__overlay {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
    z-index: 1001;
    width: 100%;
    height: 100vh;
    background-color: rgba($black, 0.7);
    font-stretch: normal;
    font-style: normal;
    .container {
      max-width: 1380px;
      @media screen and (max-width: 1380px) {
        max-width: 100%;
        margin-left: 0;
      }
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      display: none;
    }
  }
  &_sticky-wrapper {
    position: relative;
    z-index: 999;
  }
  &__expand-icon {
    z-index: 20;
    padding-right: 30px;
    text-align: right;
    cursor: pointer;
    padding-bottom: 26px;
    @media screen and (orientation: landscape) and (max-width: 990px),
      screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1024px) {
      text-align: center;
      padding-bottom: 20px;
    }
  }
  &__button-link {
    background: transparent;
    border: 0;
    text-decoration: underline;
    padding: 0;
  }
  &__right-side,
  &__right-side--compared {
    display: flex;
    flex: 0 0 80%;
    max-width: 80%;
  }
  &__right-side {
    @include breakpoint(desktop-small) {
      justify-content: flex-end;
    }
    .product-box {
      &:last-child {
        padding-right: 0;
        @include breakpoint(desktop-mid){
          padding-right: .9375rem;
        }
        @include breakpoint(tab-max){
          padding: 0 11px;
        }
      }
    }
  }
  &__right-side--compared {
    .product-box {
      margin-top: -70px;
      padding-top: 70px;
      margin-bottom: -50px;
      padding-bottom: 50px;
      @include breakpoint(desktop-large){
        padding-left: 20px;
        padding-right: 20px;
      }
      @include breakpoint(desktop-small) {
        max-width: 25%;
        width: 100%;
      }
    }
  }
  &__print-share-whislist {
    margin-bottom: 20px;
    .print-share-link {
      font-family: $helvetica-light;
      font-size: 16px;
      color: $cool-medium-grey;
      display: block;
      text-transform: capitalize;
      cursor: pointer;
      margin-bottom: 16px;
      width: fit-content;
    }
  }
}

.compare-products {
  &__sticky-header {
    padding: 25px 0;
    font-family: $helvetica-light;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    color: $light-white;
  }
  &__sticky-header-content {
    display: flex;
    position: relative;
  }
  &__sticky-collapse {
    position: absolute;
    top: 20px;
    right: 0;
    cursor: pointer;
  }
  &__sticky-header-differences {
    display: flex;
    align-items: center;
    padding-right: 20px;
    @include breakpoint(tab){
      display: none;
    }
    span {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      width: 75pt;
      margin-right: 20px;
    }
    .slider {
      width: 60px;
    }
  }
  &__sticky-header-details {
    display: flex;
  }
  &__sticky-header-product {
    color: $text-dark-grey;
    div {
      display: flex;
      flex-direction: column;
      span:first-child {
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.32px;
      }
      span:last-child {
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.4px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 180px;
        font-weight: 700;
      }
    }
  }
  &__overlay {
    width: 100%;
    position: absolute;
    bottom: 0;
    .compare-products {
      padding: 40px 32px 50px !important;
    }
  }
  &__overlay--compared {
    position: relative;
    right: 0;
    top: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    .compare-products {
      padding: 40px 0 30px;
      &__half-screen-content--compared {
        @include breakpoint(tab) {
          padding: 0 2rem;
        }
        .compare-products {
          &__left-side--compared {
            max-width: 20%;
            flex: 0 0 20%;
            padding: 0;
            margin-right: 0;
            @include breakpoint(desktop-small){
              padding-right: 3.6875rem;
            }
          }
          &__right-side--compared {
            .product-box {
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.compare-products__full-screen {
  background-color: $color-white;
  padding-bottom: 50px;
  .collapsible-accordion {
    padding-top: 40px;
    @include breakpoint(tab) {
      padding-top: 29px;
    }
    .Collapsible {
      border-bottom: none !important;
      &__trigger {
        border-bottom: 1px solid $color-dark-gray;
      }
      .accordion__header {
        display: flex;
        align-items: center;
        span {
          font-family: $lato-regular;
          font-size: 1.375rem;
          line-height: 2rem;
          letter-spacing: 0;
          color: $text-dark-grey;
          z-index: 99;
        }
        img {
          transition: all 0.5s ease;
          margin-left: 20px;
          width: 15px;
          height: 10px;
          z-index: 99;
          cursor: pointer;
        }
      }
      &.collapsible--open {
        .accordion__header {
          img {
            transform: rotate(180deg);
          }
        }
      }
      &__contentInner {
        padding-top: 30px;
        padding-bottom: 0px !important;
      }
    }
    .compare-products__accordion-container {
      &:first-child {
        .compare-products__accordion-content {
          .compare-products__spec-comparison {
            .compare-products__spec-values {
              padding-top: 190px;
              margin-top: -190px;
            }
          }
        }
      }
      &:last-child {
        .compare-products__accordion-content {
          padding-bottom: 20px;
          margin: 0;
        }
      }
    }
    .compare-products__accordion-content {
      display: flex;
      font-family: $helvetica-light;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.65px;
      margin-bottom: 20px;
      @include breakpoint(tab) {
        padding: 0 2rem;
      }
      &--highlighted {
        background-color: $cool-light-grey;
      }

      .compare-products__spec-header {
        max-width: 19.2%;
        flex: 0 0 20%;
        padding: 0;
        margin-right: 0;
        @media (min-width: 1148px) and (max-width: 1280px) { 
          max-width: 19.15%;
        }
        @media (min-width: 1024px) and (max-width: 1148px) { 
          max-width: 19%;
        }
        @include breakpoint(desktop-mid){
          padding-left: 2.25rem;
        }
        span {
          color: $text-dark-grey;
          @include ann-describtion(12px, $lato-regular, 24px);
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
      .compare-products__spec-comparison {
        display: flex;
        justify-content: flex-end;

        max-width: 80%;
        flex: 0 0 80%;

        .compare-products__spec-values {
          word-wrap: break-word;
          max-width: 25%;
          width: 100%;
          margin-left: 0;
          &:last-child {
            margin-right: 0;
          }
          padding-top: 60px;
          margin-top: -60px;
          z-index: 9;
          span {
            display: block;
            color: $color-charcoal;
            padding-bottom: 10px;

            a {
              text-decoration: underline;
            }
          }
          .compare-products__dimension-spec {
            padding-bottom: 30px;
            &:last-child {
              padding-bottom: 0px;
            }
          }
          .compare-products__color-finish {
            display: inline-block;
            width: 40px;
            height: 40px;
            border: solid 1px $light-gray;
            border-radius: 4px;
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
@include breakpoint(mobile) {
  .collapsed-widget-wrapper {
    z-index: 998;
  }
}
@include breakpoint(tab) {
  .collapsed-widget-wrapper {
    z-index: 998;
  }
  .compare-products {
    &__button {
      color: $color-medium-blue-cap;
      font-family: $lato-bold;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 10px;
      text-align: center;
      padding: 15px 26.5px;
      border: 1px solid $color-medium-blue-cap;
      margin: 0 0 10px 0;
    }
  }
}
.compare-products__sticky-header--mobile {
  display: none;
}
@media screen and (orientation: landscape) and (max-width: 990px),
  screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1024px) {
  .compare-products__sticky-header--mobile {
    display: block;
    position: sticky;
    z-index: 999;
    top: -1px;
    background-color: $charcoal;
    border-bottom: 1px solid $slate;
    padding: 15px 0;
    font-size: 1pc;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: $white;
    &__title {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: normal;
    }
    &__differences {
      display: flex;
      align-items: center;
      padding: 0 30px;
      margin-right: 30px;
      border-left: 1px solid $slate;
      border-right: 1px solid $slate;
      span {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal;
      }
      .switch {
        margin-left: 28px;
        width: 60px;
        margin-bottom: 0;
      }
      .ToggleSwitch_switchWrapper__AZQyc {
        display: flex;
        margin-left: 10px;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2pc;
    }
    &__left {
      display: flex;
      align-items: center;
    }
    &__differences span,
    &__title {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: normal;
    }
    &__title {
      margin-right: 30px;
    }
    &__righ {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .compare-products__sticky-wrapper {
    .sticky {
      top: 45px !important;
    }
  }
  .compare-products {
    .compare-card-item__content {
      display: block;
    }
    .compare-card-item__image-container,
    .place-holder__box {
      height: 138px;
    }
    .place-holder__num {
      font-size: 60px;
      line-height: 1;
      letter-spacing: -4.1px;
    }
    .place-holder__box--compared {
      height: 228px;
      width: 80%;
      .place-holder__num {
        font-size: 120px;
        line-height: 0.92;
        letter-spacing: -1.36px;
        left: 0;
      }
    }
    .compare-card-item {
      .compare-card-img {
        height: 100%;
      }
    }
    .compare-card-item__image-container--compared {
      height: 228px;
    }
  }
  .compare-products__expand-container.\--compared {
    display: none;
  }
  .compare-products__left-side--compared {
    display: none;
  }

  .compare-products__overlay--compared {
    .compare-products {
      padding: 40px 32px 35px;
    }
  }
  .compare-products__right-side--compared {
    max-width: 100% !important;
    flex: 0 0 100%;
    .product-box {
      margin: 0;
      padding: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .compare-products__full-screen {
    padding-bottom: 50px;
    .collapsible-accordion {
      padding-top: 40px;
      .Collapsible {
        .accordion__header {
          span {
            margin-left: 32px;
            font-size: 26px;
            line-height: 1.23;
            letter-spacing: -1.15px;
          }
        }
        &__contentInner {
          padding: 0px;
        }
      }
      .compare-products__accordion-container {
        &:first-child {
          .compare-products__accordion-content {
            .compare-products__spec-comparison {
              .compare-products__spec-values {
                padding-top: 0px;
                margin-top: 0px;
              }
            }
          }
        }
        &:last-child {
          .compare-products__accordion-content {
            padding-bottom: 10px;
          }
        }
      }
      .compare-products__accordion-content {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: normal;
        margin: 0px;
        padding-bottom: 30px;
        padding-top: 0px;

        .compare-products__spec-header {
          max-width: 100%;
          background-color: $half-white;
          padding: 11px 32px;
          margin-bottom: 20px;
          span {
            color: $color-dark-gray;
          }
        }
        .compare-products__spec-comparison {
          max-width: 100%;
          flex: 0 0 100%;
          margin: 0px;
          .compare-products__spec-values {
            margin: 0px;
            padding-top: 0px;
            padding-left: 0px;
            padding-right: 20px;
            &.compare-col-hover {
              background-color: inherit;
            }
            .compare-products__dimension-spec {
              padding-bottom: 20px;
            }
            .compare-products__color-finish {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
      &__skuid {
        font-size: 12px;
      }
      &__name {
        font-size: 12px;
        margin-bottom: 15px;
      }
      &__description {
        font-size: 13px;
        display: block;
      }
      &__price-section {
        font-size: 14px;
        display: block;
      }
      &__cart-button {
        font-size: 12px;
        display: block;
      }
    }
  }
}
@media screen and (max-width: 812px) {
  .compare-products__overlay {
    .compare-products {
      padding: 10px 0px 11px 32px !important;
    }
    &--compared {
      .compare-products {
        padding: 40px 32px 50px;
      }
    }
  }

  .compare-products {
    &__sticky-header--mobile__title {
      margin-right: 0px;
      padding-right: 50px;
    }

    &__sticky-header--mobile {
      padding: 10px 0px;
      background-color: $cool-light-grey;
      color: $text-medium-dark-grey;

      &__differences {
        padding: 0px 30px;
        margin-right: 30px;
        label {
          input {
            &:focus-visible,
            &:focus {
              + .round {
                outline: 2px dotted $dark-gray;
                outline-offset: 1px;
              }
            }
          }
        }
      }

      &__title {
        padding: 0px 15px;
      }

      &__left {
        img {
          filter: invert(100%);
        }
      }

      &__right {
        img {
          filter: invert(100%);
        }
      }
    }

    &__expand-icon {
      top: 0;
    }

    &__title-text {
      font-size: 18px;
      width: 175px;
      line-height: 18px;
      padding-bottom: 12px;
    }

    &__button {
      font-size: 12px;
      width: 157px;
      padding: 15px 26.5px;
      color: $color-medium-blue-cap;
      font-family: $lato-bold;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 10px;
      text-align: center;
      border: 1px solid $color-medium-blue-cap;
      margin: 0 0 10px 0;
    }

    .product-box {
      margin-right: 21px;
      @include breakpoint(tab) {
        margin-right: unset;
        padding: 0px 11px;
      }
    }
    .compare-products__half-screen-content {
      .product-tile__badge {
        .product-tile__new {
          padding: 5px 3px;
        }
        top: 1px;
        left: 1px;
      }
      @include breakpoint(tab) {
        flex-wrap: nowrap
      }
    }
    .compare-card-item {
      &__image-container {
        height: 120px;
      }

      &__skuid {
        font-size: 12px;
        color: $text-dark-grey;
      }

      &__name {
        font-size: 12px;
        color: $text-light-grey;
      }

      &__content {
        .compare-card-item {
          &__price-section {
            font-size: 16px;
            color: $text-dark-grey;
            display: none;
            .compare-card-item__list-price{
              span{
                @include breakpoint(tab){
                  display: none;
                }
              }
            }
          }
        }
      }
      &__content--compared {
        .compare-card-item {
          &__price-section {
            display: block;
          }
        }
      }
    }

    .compare-products__half-screen-content .compare-products__right-side {
      padding-left: 81px;
    }

    .place-holder {
      &__box {
        height: 120px;
      }
      &__num {
        font-size: 60px;
      }
    }

    .compare-products__mobile-title {
      display: none;
    }
  }
  .compare-products__overlay--compared {
    .compare-products {
      padding: 40px 2pc 50px;
    }
  }
  .compare-products__sticky-header--mobile {
    padding: 10px 0;
    background-color: $cool-light-grey;
    color: $text-medium-dark-grey;
  }
  .compare-products .compare-card-item__content {
      display: block;
  }
}
@media screen and (max-width: 812px) {
  .compare-products__sticky-header--mobile__title { 
      padding-left: 0;
      padding-right: 50px;
  }
}
@media screen and (max-width: 1380px) {
  .compare-products__container .container {
      margin-left: 0;
  }
}

@media screen and (orientation:landscape) and (max-width: 990px), screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1024px){  
  .compare-products__sticky-wrapper{
      .sticky{
          top: 45px !important;
      }
  }
}
@media only screen and (max-width: 860px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 991px){
  .compare-products {
      .compare-card-item{
          &__name {
              font-size: 12px;
              color: $text-dark-grey;
              line-height: 19px;
          }
          &__description{
              font-size: 10px;
              color:$text-medium-dark-grey;
              line-height: 20px;
          }
      }
  }
}

@media print {
  .compare-products{
      &__sticky-wrapper{
          display: none;
      }
      &__container{
        position: absolute;
        top: 0;
          visibility: visible;
      }
      &__full-screen{
          visibility: visible;
      }
  }
}

.compare-products__full-screen { 
  .collapsible-accordion .compare-products__accordion-content--highlighted {
      background-color: $cool-light-grey;
  }
  .collapsible-accordion .compare-products__accordion-content .compare-products__spec-comparison .compare-products__spec-values.compare-col-hover {
      background-color: $cool-medium-grey;
      z-index: 6;
      position: relative;
      @include breakpoint (tab-max) {
          min-width: 9.8125rem;
          margin-left: -11px;
      }
  }
}
.compare-products__right-side--compared .product-box {
  margin-top: -158px;
  padding-top: 158px;
  margin-bottom: -50px;
  padding-bottom: 50px;
}

.compare-products__full-screen .collapsible-accordion {
  .compare-products__accordion-container:first-child .compare-products__accordion-content .compare-products__spec-comparison{
    .compare-products__spec-values {
      padding-top: 308px;
      margin-top: -308px;
    } 
  }
  .compare-products__accordion-container .compare-products__accordion-content .compare-products__spec-comparison{
    display: flex;
    justify-content: space-between;
    max-width: 1139px;
    @include breakpoint(tab-max){
      max-width: 100%;
      justify-content: end;
    }
  }
}
.compare-products__full-screen .collapsible-accordion {
  .compare-products__accordion-container .compare-products__accordion-content .compare-products__spec-comparison{
    @include breakpoint(desktop-small){
      left: 11px;
      top: 2px;
    }
  }
}
.compare-products__full-screen .collapsible-accordion:not(:first-child){
  .compare-products__accordion-container:first-child .compare-products__accordion-content .compare-products__spec-comparison{
    .compare-products__spec-values {
      padding-top: 210px; 
      margin-top: -210px; 
    } 
  .compare-products__sticky-header--mobile__title {
    padding-left: 0;
    padding-right: 50px;
  }
}
}

@media only screen and (max-width: 860px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-products {
    .compare-card-item {
      &__name {
        font-size: 12px;
        color: $text-dark-grey;
        line-height: 19px;
      }
      &__description {
        font-size: 10px;
        color: $text-medium-dark-grey;
        line-height: 20px;
      }
    }
  }
}

@media print {
  .compare-products {
    &__sticky-wrapper {
      display: none;
    }
    &__container {
      position: relative;
      visibility: visible;
      height: 100%;
    }
    &__full-screen {
      visibility: visible;
    }
  }
  .plppage{
    &.compare-open{
      overflow-y: visible !important;
    }
  }
  .dynamicpdp.compare-open{
    overflow-y: visible !important;
    .noPrint#main-content{
      display: none;
    }
  }
}

.compare-products__full-screen
  .collapsible-accordion
  .compare-products__accordion-content
  .compare-products__spec-comparison
  .compare-products__spec-values.compare-col-hover {
  background-color: $color-extra-light-gray;
  z-index: 6;
  position: relative;
}

@media screen and (max-width: 1280px), screen and (max-height: 580px) {
  .compare-products {
    padding: 55px 0px 50px;
    &__right-side--compared {
      .product-box {
        margin-top: -55px;
        padding-top: 55px;
        margin-bottom: -50px;
        padding-bottom: 50px;
      }
    }
    &__overlay {
      .compare-products {
        padding: 40px 15px 0px !important;
      }
    }
  }
}

@include breakpoint (desktop-small) {
  .no-gutters>.col, .no-gutters>[class*=col-] {
    padding-left: 20px;
   } }

.compare-products__full-screen
  .collapsible-accordion
  .compare-products__accordion-container:first-child
  .compare-products__accordion-content
  .compare-products__spec-comparison
  .compare-products__spec-values {
  padding-top: 210px;
  margin-top: -210px;
  @media (min-width: 1024px) and (max-width: 1379px) {
    padding-top: 201px;
    margin-top: -201px;
  }
}
.compare-products__full-screen .compare-products__spec-values {
  img{
    width: 50px;
    height: 50px;
    padding: 5px;
  }
}   
#compare-products .compare-products{
  .compare-card-item{
    &__cart-button {
        color: $color-medium-blue;
        font-family: $font-lato;
        background-color: $cool-light-grey;
        border: 1px solid $color-medium-blue;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 2.5px;
        line-height: 10px;
        text-align: center;
        padding: 15px 26px;
        display: block;
        width: 100%;
      
    }}
}